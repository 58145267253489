<template>
  <div class="content-wrapper">
    <div class="content-lookup">
      <div class="lookup-left">
        <ul class="lookup-condition">
          <li class="field">
            <div class="title">년도</div>
            <ul class="content">
              <li class="item" style="width: 90px;">
                <input-number
                  ref="year"
                  v-model="searchOptions.year"
                  :allowDot="false"
                  :allowMinus="false"
                  :displayComma="false"
                  :propMaxLength="4"
                />
              </li>
            </ul>
          </li>
          <li class="field">
            <ul class="content">
              <li class="item check">
                <ul class="check">
                  <li>
                    <label>
                      <input
                          type="radio"
                          value="true"
                          v-model="searchOptions.amtFlag"
                          name="amtFlag"
                      />
                      <i/>
                      <div class="label">총매출</div>
                    </label>
                  </li>
                  <li>
                    <label>
                      <input
                          type="radio"
                          value="false"
                          v-model="searchOptions.amtFlag"
                          name="amtFlag"
                      />
                      <i/>
                      <div class="label">순매출</div>
                    </label>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
        <div class="lookup-lookup">
          <erp-button
              button-div="GET"
              :ignore="isPopupOpened"
              :is-shortcut-button="true"
              v-on:click.native="onViewButtonClicked"
          >
            조회
          </erp-button>
        </div>
      </div>
    </div>
    <div class="content-body">
      <ReportView ref="reportViewComponent" :isPopup="true"/>
      <article class="body-article">
        <section class="article-section section-01">
          <div class="section-header">
            <div class="header-left">
              <div class="header-title">월별 전년대비 매출현황</div>
              <div v-show="title.year" class="header-caption">[영업년도 : {{title.year}}년도]</div>
            </div>
            <div class="header-right">
              <ul class="header-button">
                <li class="print">
                  <erp-button
                      button-div="PRINT"
                      @click.native="print"
                      :ignore="isPopupOpened"
                      :is-shortcut-button="true"
                      >인쇄
                  </erp-button>
                </li>
                <li class="print">
                  <erp-button
                      button-div="FILE"
                      @click.native="onClickExcel"
                  >
                    Excel
                  </erp-button>
                </li>
              </ul>
            </div>
          </div>
          <div class="section-body">
            <div class="body-grid">
              <ejs-grid-wrapper
                ref="bodyGrid"
                v-bind="bodyGridProps"
                :dataSource="monthlyYearOverYearSales"
                :aggregates="bodyGridAggregates"
                @headerCellInfo="onBodyGridHeaderCellInfo"
                @queryCellInfo="onBodyGridQueryCellInfo"
              />
            </div>
          </div>
        </section>
      </article>
    </div>
  </div>
</template>

<style scoped>
body .appContent .body-article .section-body {overflow: hidden; border: none;}
</style>

<script>
import commonMixin from "@/views/layout/mixin/commonMixin";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";

import EjsGridWrapper from "@/components/common/EjsGridWrapper";
import InputNumber from "@/components/common/InputNumber";
import ReportView from '@/components/common/report/ReportView';
import ErpButton from "@/components/button/ErpButton.vue";
import {
  Aggregate,
  Resize,
  ForeignKey,
  ExcelExport,
  Page,
  Edit,
  Group,
  Filter,
} from "@syncfusion/ej2-vue-grids";

import {
  getEndOfMonth, getFormattedDate,
  getTodayNavigationDate,
} from "@/utils/date";
import {mapGetters} from "vuex";
import GolfErpAPI from "@/api/v2/GolfErpAPI";
import {
  commonCodesGetCommonCode,
  commonCodesGetCommonCodeByIdx,
  commonCodesGetCommonCodeAttrbByCodeAndIdx
} from "@/utils/commonCodes";
import {groupBy as _groupBy} from "lodash";
import {numberWithCommas} from "@/utils/number";

export default {
  name: "MonthlyYearOverYearSales",
  components: {
    EjsGridWrapper,
    InputNumber,
    ReportView,
    ErpButton,
  },
  mixins: [commonMixin, confirmDialogMixin],
  data() {
    return {
      commonCodeFields: {text: 'comName', value: 'comCode'},
      searchOptions: {
        year: null,
        amtFlag: "true",
      },
      title: {
        year: null,
      },
      monthlyYearOverYearSales: [],
      reportSearchOptions: {}
    };
  },
  async created() {
    this.searchOptions.year = await getTodayNavigationDate("YYYY");
  },
  destroyed() {},
  computed: {
    ...mapGetters(['username']),
    isPopupOpened() {
      return false;
    },
    bodyGridProps() {
      return {
        editSettings: {
          allowEditing: false,
          allowAdding: false,
          allowDeleting: false,
          mode: "Batch",
          showConfirmDialog: false,
          newRowPosition: "Bottom",
        },
        selectionSettings: {
          type: "Single", mode: "Both", enableToggle: false
        },
        provides: [
          Aggregate,
          Edit,
          ForeignKey,
          Group,
          Resize,
          Filter,
          Page,
          ExcelExport,
        ],
        allowSorting: false,
        allowFiltering: false,
        allowGrouping: false,
        allowExcelExport: true,
        noColumnWidth: 34,
        columns: [
          {
            field: "title",
            headerText: "구분",
            type: "string",
            minWidth: 16,
            width: 65,
            textAlign: "center",
          },
          // {
          //   field: "name",
          //   headerText: "구분",
          //   type: "string",
          //   minWidth: 16,
          //   width: 55,
          //   textAlign: "center",
          // },
          {
            field: "div",
            headerText: "구분",
            type: "string",
            minWidth: 16,
            width: 65,
            textAlign: "center",
          },
          {
            field: "data01",
            headerText: "1월",
            type: "number",
            format: "N",
            minWidth: 16,
            width: 90,
            textAlign: "right",
          },
          {
            field: "data02",
            headerText: "2월",
            type: "number",
            format: "N",
            minWidth: 16,
            width: 90,
            textAlign: "right",
          },
          {
            field: "data03",
            headerText: "3월",
            type: "number",
            format: "N",
            minWidth: 16,
            width: 90,
            textAlign: "right",
          },
          {
            field: "data04",
            headerText: "4월",
            type: "number",
            format: "N",
            minWidth: 16,
            width: 90,
            textAlign: "right",
          },
          {
            field: "data05",
            headerText: "5월",
            type: "number",
            format: "N",
            minWidth: 16,
            width: 90,
            textAlign: "right",
          },
          {
            field: "data06",
            headerText: "6월",
            type: "number",
            format: "N",
            minWidth: 16,
            width: 90,
            textAlign: "right",
          },
          {
            field: "firstHalf",
            headerText: "상반기",
            type: "number",
            format: "N",
            minWidth: 16,
            width: 100,
            textAlign: "right",
          },
          {
            field: "data07",
            headerText: "7월",
            type: "number",
            format: "N",
            minWidth: 16,
            width: 90,
            textAlign: "right",
          },
          {
            field: "data08",
            headerText: "8월",
            type: "number",
            format: "N",
            minWidth: 16,
            width: 90,
            textAlign: "right",
          },
          {
            field: "data09",
            headerText: "9월",
            type: "number",
            format: "N",
            minWidth: 16,
            width: 90,
            textAlign: "right",
          },
          {
            field: "data10",
            headerText: "10월",
            type: "number",
            format: "N",
            minWidth: 16,
            width: 90,
            textAlign: "right",
          },
          {
            field: "data11",
            headerText: "11월",
            type: "number",
            format: "N",
            minWidth: 16,
            width: 90,
            textAlign: "right",
          },
          {
            field: "data12",
            headerText: "12월",
            type: "number",
            format: "N",
            minWidth: 16,
            width: 90,
            textAlign: "right",
          },
          {
            field: "secondHalf",
            headerText: "하반기",
            type: "number",
            format: "N",
            minWidth: 16,
            width: 100,
            textAlign: "right",
          },
          {
            field: "total",
            headerText: "합계",
            type: "number",
            format: "N",
            minWidth: 16,
            width: 110,
            textAlign: "right",
          },
        ],
      };
    },
    bodyGridAggregates() {
      return [];
    },
  },
  mounted() {},
  methods: {
    async onViewButtonClicked() {
      const fromDate = (this.searchOptions.year - 1) + "-01-01";
      const monthLastDay = await getEndOfMonth(Number(this.searchOptions.year), 12);
      const toDate = this.searchOptions.year + "-12-" + monthLastDay;

      const {
        tgClosePaymts,
        tgCloseSalesStores,
      } = await GolfErpAPI.fetchMonthlyYearOverYearSales({
        fromDate: fromDate,
        toDate: toDate
      });

      const prevTgClosePaymts = tgClosePaymts.filter(item => getFormattedDate(item.bsnDate, "YYYY") === getFormattedDate(fromDate, "YYYY"));
      const thisTgClosePaymts = tgClosePaymts.filter(item => getFormattedDate(item.bsnDate, "YYYY") === getFormattedDate(toDate, "YYYY"));
      const prevTgCloseSalesStores = tgCloseSalesStores.filter(item => getFormattedDate(item.bsnDate, "YYYY") === getFormattedDate(fromDate, "YYYY"));
      const thisTgCloseSalesStores = tgCloseSalesStores.filter(item => getFormattedDate(item.bsnDate, "YYYY") === getFormattedDate(toDate, "YYYY"));

      const months = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];

      let memberGradeIdx1 = [];
      commonCodesGetCommonCodeByIdx("MEMBER_GRADE", 1).map(item => {
        item.comCodeAttrbList.map(attrb => {
          memberGradeIdx1.push(attrb);
        });
      });
      let memberGradeIdx1AttrbGroupBy = [];
      for (let [key, value] of Object.entries(_groupBy(memberGradeIdx1, "attrb"))) {
        memberGradeIdx1AttrbGroupBy.push({attrb: key, attrbName: value[0]?.attrbName, isTotal: false});
      }
      const bsnCodes = commonCodesGetCommonCode("BSN_CODE").filter(item => item.comCode !== "CLOSE" && (item.comCode === 'WEEKDAY' || item.comCode === 'WEEKEND'));
      bsnCodes.push({comCode: "TOTAL", comName: "소계"});
      bsnCodes.push({comCode: "PRE_TOTAL", comName: "전년소계"});
      bsnCodes.push({comCode: "PRE_YEAR", comName: "전년대비"});

      const loopDatas = [
        {code: "VISIT_PER", name: "내장객수"},
        {code: "SALES_BY_FEE", name: "프론트"},  // ["FOOD","FEE"] "FEE" 추가..
        {code: "SALES_BY_FOOD", name: "식음매출"},
        // {code: "SALES_BY_SHOP", name: "프로샵"}, // ["FOOD","SHOP","FEE"] // SHOP 제외
        {code: "SALES_BY_TOTAL", name: "총매출"},
      ];

      let monthlyYearOverYearSales = [];

      loopDatas.forEach(loopData => {
        let obj = {
          title: loopData.name,
        };
        // 내장객 수
        if (loopData.code === "VISIT_PER") {
          memberGradeIdx1AttrbGroupBy.forEach((memberGrade, idx) => {
            bsnCodes.forEach((bsnCode, bsnCodeIdx) => {
              obj.name = memberGrade.attrbName;
              obj.div = bsnCode.comName;
              obj.isTitleRowSpan = (idx === 0) && (bsnCodeIdx === 0);
              // obj.titleRowSpan = (idx === 0) && (bsnCodeIdx === 0) ? (memberGradeIdx1AttrbGroupBy.length * bsnCodes.length) + bsnCodes.length : 0;
              obj.titleRowSpan = idx === 0 ? bsnCodes.length : 0;
              obj.isNameRowSpan = bsnCodeIdx === 0;
              obj.nameRowSpan = bsnCodeIdx === 0 ? bsnCodes.length : 0;
              obj.isNameColSpan = false;
              obj.isBottomSolidLine = bsnCodeIdx === (bsnCodes.length - 1);
              obj.isTotal = bsnCode.comCode === "TOTAL";
              obj.isPrevTotal = bsnCode.comCode === "PRE_TOTAL";
              obj.isPrevYear = bsnCode.comCode === "PRE_YEAR";

              months.forEach(month => {
                obj["data".concat(month)] =
                  bsnCode.comCode === "PRE_YEAR" ?
                    (monthlyYearOverYearSales[monthlyYearOverYearSales.length - 2]["data".concat(month)] || 0) -
                    (prevTgClosePaymts
                      .filter(item =>
                        getFormattedDate(item.bsnDate, "MM") === month &&
                        memberGradeIdx1.filter(memberGradeIdx => memberGradeIdx.attrb === memberGrade.attrb).map(memberGradeIdx => memberGradeIdx.comCode).includes(item.sumDiv))
                      .map(item => item.visitTotPer)
                      .reduce((acc, cur) => acc + cur, 0)) :

                    bsnCode.comCode ==="PRE_TOTAL" ?
                      (prevTgClosePaymts
                        .filter(item =>
                          getFormattedDate(item.bsnDate, "MM") === month &&
                          memberGradeIdx1.filter(memberGradeIdx => memberGradeIdx.attrb === memberGrade.attrb).map(memberGradeIdx => memberGradeIdx.comCode).includes(item.sumDiv))
                        .map(item => item.visitTotPer)
                        .reduce((acc, cur) => acc + cur, 0)) :

                      (thisTgClosePaymts
                        .filter(item =>
                          getFormattedDate(item.bsnDate, "MM") === month &&
                          (bsnCode.comCode === "TOTAL" ? true : item.bsnCode === bsnCode.comCode) &&
                          memberGradeIdx1.filter(memberGradeIdx => memberGradeIdx.attrb === memberGrade.attrb).map(memberGradeIdx => memberGradeIdx.comCode).includes(item.sumDiv))
                        .map(item => item.visitTotPer)
                        .reduce((acc, cur) => acc + cur, 0));

                if (bsnCode.comCode === "PRE_YEAR") {
                  obj["isData".concat(month)] = (monthlyYearOverYearSales[monthlyYearOverYearSales.length - 2]["data".concat(month)] || 0) > 0;
                  obj["dataByPercent".concat(month)] =
                    (prevTgClosePaymts
                      .filter(item =>
                        getFormattedDate(item.bsnDate, "MM") === month &&
                        memberGradeIdx1.filter(memberGradeIdx => memberGradeIdx.attrb === memberGrade.attrb).map(memberGradeIdx => memberGradeIdx.comCode).includes(item.sumDiv))
                      .map(item => item.visitTotPer)
                      .reduce((acc, cur) => acc + cur, 0)) > 0 ?
                      Math.round(((monthlyYearOverYearSales[monthlyYearOverYearSales.length - 2]["data".concat(month)] || 0) /
                      (prevTgClosePaymts
                        .filter(item =>
                          getFormattedDate(item.bsnDate, "MM") === month &&
                          memberGradeIdx1.filter(memberGradeIdx => memberGradeIdx.attrb === memberGrade.attrb).map(memberGradeIdx => memberGradeIdx.comCode).includes(item.sumDiv))
                        .map(item => item.visitTotPer)
                        .reduce((acc, cur) => acc + cur, 0))) * 100) - 100 :
                      100;
                }
              });

              obj.firstHalf =
                bsnCode.comCode === "PRE_YEAR" ?
                  (monthlyYearOverYearSales[monthlyYearOverYearSales.length - 2].firstHalf || 0) -
                  (prevTgClosePaymts
                    .filter(item =>
                      ["01","02","03","04","05","06"].includes(getFormattedDate(item.bsnDate, "MM")) &&
                      memberGradeIdx1.filter(memberGradeIdx => memberGradeIdx.attrb === memberGrade.attrb).map(memberGradeIdx => memberGradeIdx.comCode).includes(item.sumDiv))
                    .map(item => item.visitTotPer)
                    .reduce((acc, cur) => acc + cur, 0)) :

                  bsnCode.comCode === "PRE_TOTAL" ?
                    (prevTgClosePaymts
                      .filter(item =>
                        ["01","02","03","04","05","06"].includes(getFormattedDate(item.bsnDate, "MM")) &&
                        memberGradeIdx1.filter(memberGradeIdx => memberGradeIdx.attrb === memberGrade.attrb).map(memberGradeIdx => memberGradeIdx.comCode).includes(item.sumDiv))
                      .map(item => item.visitTotPer)
                      .reduce((acc, cur) => acc + cur, 0)) :

                    (thisTgClosePaymts
                      .filter(item =>
                        ["01","02","03","04","05","06"].includes(getFormattedDate(item.bsnDate, "MM")) &&
                        (bsnCode.comCode === "TOTAL" ? true : item.bsnCode === bsnCode.comCode) &&
                        memberGradeIdx1.filter(memberGradeIdx => memberGradeIdx.attrb === memberGrade.attrb).map(memberGradeIdx => memberGradeIdx.comCode).includes(item.sumDiv))
                      .map(item => item.visitTotPer)
                      .reduce((acc, cur) => acc + cur, 0));

              obj.secondHalf =
                bsnCode.comCode === "PRE_YEAR" ?
                  (monthlyYearOverYearSales[monthlyYearOverYearSales.length - 2].secondHalf || 0) -
                  (prevTgClosePaymts
                    .filter(item =>
                      ["07","08","09","10","11","12"].includes(getFormattedDate(item.bsnDate, "MM")) &&
                      memberGradeIdx1.filter(memberGradeIdx => memberGradeIdx.attrb === memberGrade.attrb).map(memberGradeIdx => memberGradeIdx.comCode).includes(item.sumDiv))
                    .map(item => item.visitTotPer)
                    .reduce((acc, cur) => acc + cur, 0)) :

                  bsnCode.comCode === "PRE_TOTAL" ?
                    (prevTgClosePaymts
                      .filter(item =>
                          ["07","08","09","10","11","12"].includes(getFormattedDate(item.bsnDate, "MM")) &&
                          memberGradeIdx1.filter(memberGradeIdx => memberGradeIdx.attrb === memberGrade.attrb).map(memberGradeIdx => memberGradeIdx.comCode).includes(item.sumDiv))
                      .map(item => item.visitTotPer)
                      .reduce((acc, cur) => acc + cur, 0)) :

                    (thisTgClosePaymts
                      .filter(item =>
                        ["07","08","09","10","11","12"].includes(getFormattedDate(item.bsnDate, "MM")) &&
                        (bsnCode.comCode === "TOTAL" ? true : item.bsnCode === bsnCode.comCode) &&
                        memberGradeIdx1.filter(memberGradeIdx => memberGradeIdx.attrb === memberGrade.attrb).map(memberGradeIdx => memberGradeIdx.comCode).includes(item.sumDiv))
                      .map(item => item.visitTotPer)
                      .reduce((acc, cur) => acc + cur, 0));

              obj.total =
                bsnCode.comCode === "PRE_YEAR" ?
                  (monthlyYearOverYearSales[monthlyYearOverYearSales.length - 2].total || 0) -
                  (prevTgClosePaymts
                    .filter(item =>
                      memberGradeIdx1.filter(memberGradeIdx => memberGradeIdx.attrb === memberGrade.attrb).map(memberGradeIdx => memberGradeIdx.comCode).includes(item.sumDiv))
                    .map(item => item.visitTotPer)
                    .reduce((acc, cur) => acc + cur, 0)) :

                  bsnCode.comCode === "PRE_TOTAL" ?
                    (prevTgClosePaymts
                        .filter(item =>
                            memberGradeIdx1.filter(memberGradeIdx => memberGradeIdx.attrb === memberGrade.attrb).map(memberGradeIdx => memberGradeIdx.comCode).includes(item.sumDiv))
                        .map(item => item.visitTotPer)
                        .reduce((acc, cur) => acc + cur, 0)) :

                    (thisTgClosePaymts
                      .filter(item =>
                        (bsnCode.comCode === "TOTAL" ? true : item.bsnCode === bsnCode.comCode) &&
                        memberGradeIdx1.filter(memberGradeIdx => memberGradeIdx.attrb === memberGrade.attrb).map(memberGradeIdx => memberGradeIdx.comCode).includes(item.sumDiv))
                      .map(item => item.visitTotPer)
                      .reduce((acc, cur) => acc + cur, 0));

              if (bsnCode.comCode === "PRE_YEAR") {
                obj.isFirstHalfData = (monthlyYearOverYearSales[monthlyYearOverYearSales.length - 2].firstHalf || 0) > 0;
                obj.isSecondHalfData = (monthlyYearOverYearSales[monthlyYearOverYearSales.length - 2].secondHalf || 0) > 0;
                obj.isTotalData = (monthlyYearOverYearSales[monthlyYearOverYearSales.length - 2].total || 0) > 0;

                obj.firstHalfByPercent =
                  (prevTgClosePaymts
                    .filter(item =>
                      ["01","02","03","04","05","06"].includes(getFormattedDate(item.bsnDate, "MM")) &&
                      memberGradeIdx1.filter(memberGradeIdx => memberGradeIdx.attrb === memberGrade.attrb).map(memberGradeIdx => memberGradeIdx.comCode).includes(item.sumDiv))
                    .map(item => item.visitTotPer)
                    .reduce((acc, cur) => acc + cur, 0)) > 0 ?
                    Math.round(((monthlyYearOverYearSales[monthlyYearOverYearSales.length - 2].firstHalf || 0) /
                    (prevTgClosePaymts
                      .filter(item =>
                        ["01","02","03","04","05","06"].includes(getFormattedDate(item.bsnDate, "MM")) &&
                        memberGradeIdx1.filter(memberGradeIdx => memberGradeIdx.attrb === memberGrade.attrb).map(memberGradeIdx => memberGradeIdx.comCode).includes(item.sumDiv))
                      .map(item => item.visitTotPer)
                      .reduce((acc, cur) => acc + cur, 0))) * 100) - 100 :
                    100;
                obj.secondHalfByPercent =
                  (prevTgClosePaymts
                    .filter(item =>
                      ["07","08","09","10","11","12"].includes(getFormattedDate(item.bsnDate, "MM")) &&
                      memberGradeIdx1.filter(memberGradeIdx => memberGradeIdx.attrb === memberGrade.attrb).map(memberGradeIdx => memberGradeIdx.comCode).includes(item.sumDiv))
                    .map(item => item.visitTotPer)
                    .reduce((acc, cur) => acc + cur, 0)) > 0 ?
                    Math.round(((monthlyYearOverYearSales[monthlyYearOverYearSales.length - 2].secondHalf || 0) /
                    (prevTgClosePaymts
                      .filter(item =>
                        ["07","08","09","10","11","12"].includes(getFormattedDate(item.bsnDate, "MM")) &&
                        memberGradeIdx1.filter(memberGradeIdx => memberGradeIdx.attrb === memberGrade.attrb).map(memberGradeIdx => memberGradeIdx.comCode).includes(item.sumDiv))
                      .map(item => item.visitTotPer)
                      .reduce((acc, cur) => acc + cur, 0))) * 100) - 100 :
                    100;
                obj.totalByPercent =
                  (prevTgClosePaymts
                    .filter(item =>
                      memberGradeIdx1.filter(memberGradeIdx => memberGradeIdx.attrb === memberGrade.attrb).map(memberGradeIdx => memberGradeIdx.comCode).includes(item.sumDiv))
                    .map(item => item.visitTotPer)
                    .reduce((acc, cur) => acc + cur, 0)) > 0 ?
                    Math.round(((monthlyYearOverYearSales[monthlyYearOverYearSales.length - 2].total || 0) /
                    (prevTgClosePaymts
                      .filter(item =>
                        memberGradeIdx1.filter(memberGradeIdx => memberGradeIdx.attrb === memberGrade.attrb).map(memberGradeIdx => memberGradeIdx.comCode).includes(item.sumDiv))
                      .map(item => item.visitTotPer)
                      .reduce((acc, cur) => acc + cur, 0))) * 100) - 100 :
                    100;
              }

              monthlyYearOverYearSales.push(JSON.parse(JSON.stringify(obj)));
            });
          });

          bsnCodes.forEach((bsnCode, bsnCodeIdx) => {
            obj.name = bsnCode.comName;
            obj.div = bsnCode.comName;
            obj.isNameRowSpan = false;
            obj.isNameColSpan = true;
            obj.nameColSpan = 2;
            obj.isBottomSolidLine = bsnCodeIdx === (bsnCodes.length - 1);
            obj.isTotal = bsnCode.comCode === "TOTAL";
            obj.isPrevTotal = bsnCode.comCode === "PRE_TOTAL";
            obj.isPrevYear = bsnCode.comCode === "PRE_YEAR";

            months.forEach(month => {
              obj["data".concat(month)] =
                bsnCode.comCode === "PRE_YEAR" ?
                  (monthlyYearOverYearSales[monthlyYearOverYearSales.length - 2]["data".concat(month)] || 0) -
                  (prevTgClosePaymts
                    .filter(item => getFormattedDate(item.bsnDate, "MM") === month)
                    .map(item => item.visitTotPer)
                    .reduce((acc, cur) => acc + cur, 0)) :

                  bsnCode.comCode === "PRE_YEAR" ?
                    (prevTgClosePaymts
                      .filter(item => getFormattedDate(item.bsnDate, "MM") === month)
                      .map(item => item.visitTotPer)
                      .reduce((acc, cur) => acc + cur, 0)) :

                    (thisTgClosePaymts
                      .filter(item =>
                        getFormattedDate(item.bsnDate, "MM") === month &&
                        (bsnCode.comCode === "TOTAL" ? true : item.bsnCode === bsnCode.comCode))
                      .map(item => item.visitTotPer)
                      .reduce((acc, cur) => acc + cur, 0));

              if (bsnCode.comCode === "PRE_YEAR") {
                obj["isData".concat(month)] = (monthlyYearOverYearSales[monthlyYearOverYearSales.length - 2]["data".concat(month)] || 0) > 0;
                obj["dataByPercent".concat(month)] =
                  (prevTgClosePaymts
                    .filter(item => getFormattedDate(item.bsnDate, "MM") === month)
                    .map(item => item.visitTotPer)
                    .reduce((acc, cur) => acc + cur, 0)) > 0 ?
                    Math.round(((monthlyYearOverYearSales[monthlyYearOverYearSales.length - 2]["data".concat(month)] || 0) /
                    (prevTgClosePaymts
                      .filter(item => getFormattedDate(item.bsnDate, "MM") === month)
                      .map(item => item.visitTotPer)
                      .reduce((acc, cur) => acc + cur, 0))) * 100) - 100 :
                    100;
              }
            });

            obj.firstHalf =
              bsnCode.comCode === "PRE_YEAR" ?
                (monthlyYearOverYearSales[monthlyYearOverYearSales.length - 2].firstHalf || 0) -
                (prevTgClosePaymts
                  .filter(item => ["01","02","03","04","05","06"].includes(getFormattedDate(item.bsnDate, "MM")))
                  .map(item => item.visitTotPer)
                  .reduce((acc, cur) => acc + cur, 0)) :

                bsnCode.comCode === "PRE_TOTAL" ?
                  (prevTgClosePaymts
                    .filter(item => ["01","02","03","04","05","06"].includes(getFormattedDate(item.bsnDate, "MM")))
                    .map(item => item.visitTotPer)
                    .reduce((acc, cur) => acc + cur, 0)) :

                  (thisTgClosePaymts
                    .filter(item =>
                      ["01","02","03","04","05","06"].includes(getFormattedDate(item.bsnDate, "MM")) &&
                      (bsnCode.comCode === "TOTAL" ? true : item.bsnCode === bsnCode.comCode))
                    .map(item => item.visitTotPer)
                    .reduce((acc, cur) => acc + cur, 0));

            obj.secondHalf =
              bsnCode.comCode === "PRE_YEAR" ?
                (monthlyYearOverYearSales[monthlyYearOverYearSales.length - 2].secondHalf || 0) -
                (prevTgClosePaymts
                  .filter(item => ["07","08","09","10","11","12"].includes(getFormattedDate(item.bsnDate, "MM")))
                  .map(item => item.visitTotPer)
                  .reduce((acc, cur) => acc + cur, 0)) :

                bsnCode.comCode === "PRE_TOTAL" ?
                  (prevTgClosePaymts
                    .filter(item => ["07","08","09","10","11","12"].includes(getFormattedDate(item.bsnDate, "MM")))
                    .map(item => item.visitTotPer)
                    .reduce((acc, cur) => acc + cur, 0)) :

                  (thisTgClosePaymts
                    .filter(item =>
                      ["07","08","09","10","11","12"].includes(getFormattedDate(item.bsnDate, "MM")) &&
                      (bsnCode.comCode === "TOTAL" ? true : item.bsnCode === bsnCode.comCode))
                    .map(item => item.visitTotPer)
                    .reduce((acc, cur) => acc + cur, 0));

            obj.total =
              bsnCode.comCode === "PRE_YEAR" ?
                (monthlyYearOverYearSales[monthlyYearOverYearSales.length - 2].total || 0) -
                (prevTgClosePaymts
                  .map(item => item.visitTotPer)
                  .reduce((acc, cur) => acc + cur, 0)) :

                bsnCode.comCode === "PRE_TOTAL" ?
                  (prevTgClosePaymts
                    .map(item => item.visitTotPer)
                    .reduce((acc, cur) => acc + cur, 0)) :

                  (thisTgClosePaymts
                    .filter(item =>
                      (bsnCode.comCode === "TOTAL" ? true : item.bsnCode === bsnCode.comCode))
                    .map(item => item.visitTotPer)
                    .reduce((acc, cur) => acc + cur, 0));

            if (bsnCode.comCode === "PRE_YEAR") {
              obj.isFirstHalfData = (monthlyYearOverYearSales[monthlyYearOverYearSales.length - 2].firstHalf || 0) > 0;
              obj.isSecondHalfData = (monthlyYearOverYearSales[monthlyYearOverYearSales.length - 2].secondHalf || 0) > 0;
              obj.isTotalData = (monthlyYearOverYearSales[monthlyYearOverYearSales.length - 2].total || 0) > 0;

              obj.firstHalfByPercent =
                (prevTgClosePaymts
                  .filter(item => ["01","02","03","04","05","06"].includes(getFormattedDate(item.bsnDate, "MM")))
                  .map(item => item.visitTotPer)
                  .reduce((acc, cur) => acc + cur, 0)) > 0 ?
                  Math.round(((monthlyYearOverYearSales[monthlyYearOverYearSales.length - 2].firstHalf || 0) /
                  (prevTgClosePaymts
                    .filter(item => ["01","02","03","04","05","06"].includes(getFormattedDate(item.bsnDate, "MM")))
                    .map(item => item.visitTotPer)
                    .reduce((acc, cur) => acc + cur, 0))) * 100) - 100 :
                  100;
              obj.secondHalfByPercent =
                (prevTgClosePaymts
                  .filter(item => ["07","08","09","10","11","12"].includes(getFormattedDate(item.bsnDate, "MM")))
                  .map(item => item.visitTotPer)
                  .reduce((acc, cur) => acc + cur, 0)) > 0 ?
                  Math.round(((monthlyYearOverYearSales[monthlyYearOverYearSales.length - 2].secondHalf || 0) /
                  (prevTgClosePaymts
                    .filter(item => ["07","08","09","10","11","12"].includes(getFormattedDate(item.bsnDate, "MM")))
                    .map(item => item.visitTotPer)
                    .reduce((acc, cur) => acc + cur, 0))) * 100) - 100 :
                  100;
              obj.totalByPercent =
                (prevTgClosePaymts
                  .map(item => item.visitTotPer)
                  .reduce((acc, cur) => acc + cur, 0)) > 0 ?
                  Math.round(((monthlyYearOverYearSales[monthlyYearOverYearSales.length - 2].total || 0) /
                  (prevTgClosePaymts
                    .map(item => item.visitTotPer)
                    .reduce((acc, cur) => acc + cur, 0))) * 100) - 100 :
                  100;
            }

            monthlyYearOverYearSales.push(JSON.parse(JSON.stringify(obj)));
          });
        } else {
          bsnCodes.forEach((bsnCode, idx) => {
            obj.name = bsnCode.comName;
            obj.div = bsnCode.comName;
            obj.isTitleRowSpan = idx === 0;
            obj.titleRowSpan = idx === 0 ? bsnCodes.length : 0;
            obj.isNameRowSpan = false;
            obj.isNameColSpan = true;
            obj.nameColSpan = 2;
            obj.isBottomSolidLine = idx === (bsnCodes.length - 1);
            obj.isTotal = bsnCode.comCode === "TOTAL";
            obj.isPrevTotal = bsnCode.comCode === "PRE_TOTAL";
            obj.isPrevYear = bsnCode.comCode === "PRE_YEAR";

            months.forEach(month => {
              obj["data".concat(month)] =
                  bsnCode.comCode === "PRE_YEAR" ?
                      (monthlyYearOverYearSales[monthlyYearOverYearSales.length - 2]["data".concat(month)] || 0) -
                      (prevTgCloseSalesStores
                          .filter(item =>
                              getFormattedDate(item.bsnDate, "MM") === month &&
                              (loopData.code === "SALES_BY_TOTAL" ?
                                  ["FOOD", "FEE"].includes(commonCodesGetCommonCodeAttrbByCodeAndIdx("STORE_CODE", item.storeCode, 3, false)) :
                                  commonCodesGetCommonCodeAttrbByCodeAndIdx("STORE_CODE", item.storeCode, 3, false) === loopData.code.substring(9, loopData.code.length)))
                          .map(item => this.searchOptions.amtFlag === "true" ? item.totSalesAmt : (item.netAmt + item.notaxAmt))
                          .reduce((acc, cur) => acc + cur, 0)) :

                      bsnCode.comCode === "PRE_TOTAL" ?
                        (prevTgCloseSalesStores
                          .filter(item =>
                              getFormattedDate(item.bsnDate, "MM") === month &&
                              (loopData.code === "SALES_BY_TOTAL" ?
                                  ["FOOD", "FEE"].includes(commonCodesGetCommonCodeAttrbByCodeAndIdx("STORE_CODE", item.storeCode, 3, false)) :
                                  commonCodesGetCommonCodeAttrbByCodeAndIdx("STORE_CODE", item.storeCode, 3, false) === loopData.code.substring(9, loopData.code.length)))
                          .map(item => this.searchOptions.amtFlag === "true" ? item.totSalesAmt : (item.netAmt + item.notaxAmt))
                          .reduce((acc, cur) => acc + cur, 0)) :

                        (thisTgCloseSalesStores
                          .filter(item =>
                              getFormattedDate(item.bsnDate, "MM") === month &&
                              (bsnCode.comCode === "TOTAL" ? true : item.bsnCode === bsnCode.comCode) &&
                              (loopData.code === "SALES_BY_TOTAL" ?
                                  ["FOOD", "FEE"].includes(commonCodesGetCommonCodeAttrbByCodeAndIdx("STORE_CODE", item.storeCode, 3, false)) :
                                  commonCodesGetCommonCodeAttrbByCodeAndIdx("STORE_CODE", item.storeCode, 3, false) === loopData.code.substring(9, loopData.code.length)))
                          .map(item => this.searchOptions.amtFlag === "true" ? item.totSalesAmt : (item.netAmt + item.notaxAmt))
                          .reduce((acc, cur) => acc + cur, 0));

              if (bsnCode.comCode === "PRE_YEAR") {
                obj["isData".concat(month)] = (monthlyYearOverYearSales[monthlyYearOverYearSales.length - 2]["data".concat(month)] || 0) > 0;
                obj["dataByPercent".concat(month)] =
                    (prevTgCloseSalesStores
                        .filter(item =>
                            getFormattedDate(item.bsnDate, "MM") === month &&
                            (loopData.code === "SALES_BY_TOTAL" ?
                                ["FOOD", "FEE"].includes(commonCodesGetCommonCodeAttrbByCodeAndIdx("STORE_CODE", item.storeCode, 3, false)) :
                                commonCodesGetCommonCodeAttrbByCodeAndIdx("STORE_CODE", item.storeCode, 3, false) === loopData.code.substring(9, loopData.code.length)))
                        .map(item => this.searchOptions.amtFlag === "true" ? item.totSalesAmt : (item.netAmt + item.notaxAmt))
                        .reduce((acc, cur) => acc + cur, 0)) > 0 ?
                        Math.round(((monthlyYearOverYearSales[monthlyYearOverYearSales.length - 2]["data".concat(month)] || 0) /
                            (prevTgCloseSalesStores
                                .filter(item =>
                                    getFormattedDate(item.bsnDate, "MM") === month &&
                                    (loopData.code === "SALES_BY_TOTAL" ?
                                        ["FOOD", "FEE"].includes(commonCodesGetCommonCodeAttrbByCodeAndIdx("STORE_CODE", item.storeCode, 3, false)) :
                                        commonCodesGetCommonCodeAttrbByCodeAndIdx("STORE_CODE", item.storeCode, 3, false) === loopData.code.substring(9, loopData.code.length)))
                                .map(item => this.searchOptions.amtFlag === "true" ? item.totSalesAmt : (item.netAmt + item.notaxAmt))
                                .reduce((acc, cur) => acc + cur, 0))) * 100) - 100 :
                        100;
              }
            });

            obj.firstHalf =
                bsnCode.comCode === "PRE_YEAR" ?
                    (monthlyYearOverYearSales[monthlyYearOverYearSales.length - 2].firstHalf || 0) -
                    (prevTgCloseSalesStores
                        .filter(item =>
                            ["01", "02", "03", "04", "05", "06"].includes(getFormattedDate(item.bsnDate, "MM")) &&
                            (loopData.code === "SALES_BY_TOTAL" ?
                                ["FOOD", "FEE"].includes(commonCodesGetCommonCodeAttrbByCodeAndIdx("STORE_CODE", item.storeCode, 3, false)) :
                                commonCodesGetCommonCodeAttrbByCodeAndIdx("STORE_CODE", item.storeCode, 3, false) === loopData.code.substring(9, loopData.code.length)))
                        .map(item => this.searchOptions.amtFlag === "true" ? item.totSalesAmt : (item.netAmt + item.notaxAmt))
                        .reduce((acc, cur) => acc + cur, 0)) :

                    bsnCode.comCode === "PRE_TOTAL" ?
                      (prevTgCloseSalesStores
                        .filter(item =>
                            ["01", "02", "03", "04", "05", "06"].includes(getFormattedDate(item.bsnDate, "MM")) &&
                            (loopData.code === "SALES_BY_TOTAL" ?
                                ["FOOD", "FEE"].includes(commonCodesGetCommonCodeAttrbByCodeAndIdx("STORE_CODE", item.storeCode, 3, false)) :
                                commonCodesGetCommonCodeAttrbByCodeAndIdx("STORE_CODE", item.storeCode, 3, false) === loopData.code.substring(9, loopData.code.length)))
                        .map(item => this.searchOptions.amtFlag === "true" ? item.totSalesAmt : (item.netAmt + item.notaxAmt))
                        .reduce((acc, cur) => acc + cur, 0)) :

                      (thisTgCloseSalesStores
                        .filter(item =>
                            ["01", "02", "03", "04", "05", "06"].includes(getFormattedDate(item.bsnDate, "MM")) &&
                            (bsnCode.comCode === "TOTAL" ? true : item.bsnCode === bsnCode.comCode) &&
                            (loopData.code === "SALES_BY_TOTAL" ?
                                ["FOOD", "FEE"].includes(commonCodesGetCommonCodeAttrbByCodeAndIdx("STORE_CODE", item.storeCode, 3, false)) :
                                commonCodesGetCommonCodeAttrbByCodeAndIdx("STORE_CODE", item.storeCode, 3, false) === loopData.code.substring(9, loopData.code.length)))
                        .map(item => this.searchOptions.amtFlag === "true" ? item.totSalesAmt : (item.netAmt + item.notaxAmt))
                        .reduce((acc, cur) => acc + cur, 0));

            obj.secondHalf =
                bsnCode.comCode === "PRE_YEAR" ?
                    (monthlyYearOverYearSales[monthlyYearOverYearSales.length - 2].secondHalf || 0) -
                    (prevTgCloseSalesStores
                        .filter(item =>
                            ["07", "08", "09", "10", "11", "12"].includes(getFormattedDate(item.bsnDate, "MM")) &&
                            (loopData.code === "SALES_BY_TOTAL" ?
                                ["FOOD", "FEE"].includes(commonCodesGetCommonCodeAttrbByCodeAndIdx("STORE_CODE", item.storeCode, 3, false)) :
                                commonCodesGetCommonCodeAttrbByCodeAndIdx("STORE_CODE", item.storeCode, 3, false) === loopData.code.substring(9, loopData.code.length)))
                        .map(item => this.searchOptions.amtFlag === "true" ? item.totSalesAmt : (item.netAmt + item.notaxAmt))
                        .reduce((acc, cur) => acc + cur, 0)) :

                    bsnCode.comCode === "PRE_TOTAL" ?
                      (prevTgCloseSalesStores
                        .filter(item =>
                            ["07", "08", "09", "10", "11", "12"].includes(getFormattedDate(item.bsnDate, "MM")) &&
                            (loopData.code === "SALES_BY_TOTAL" ?
                                ["FOOD", "FEE"].includes(commonCodesGetCommonCodeAttrbByCodeAndIdx("STORE_CODE", item.storeCode, 3, false)) :
                                commonCodesGetCommonCodeAttrbByCodeAndIdx("STORE_CODE", item.storeCode, 3, false) === loopData.code.substring(9, loopData.code.length)))
                        .map(item => this.searchOptions.amtFlag === "true" ? item.totSalesAmt : (item.netAmt + item.notaxAmt))
                        .reduce((acc, cur) => acc + cur, 0)) :

                      (thisTgCloseSalesStores
                        .filter(item =>
                            ["07", "08", "09", "10", "11", "12"].includes(getFormattedDate(item.bsnDate, "MM")) &&
                            (bsnCode.comCode === "TOTAL" ? true : item.bsnCode === bsnCode.comCode) &&
                            (loopData.code === "SALES_BY_TOTAL" ?
                                ["FOOD", "FEE"].includes(commonCodesGetCommonCodeAttrbByCodeAndIdx("STORE_CODE", item.storeCode, 3, false)) :
                                commonCodesGetCommonCodeAttrbByCodeAndIdx("STORE_CODE", item.storeCode, 3, false) === loopData.code.substring(9, loopData.code.length)))
                        .map(item => this.searchOptions.amtFlag === "true" ? item.totSalesAmt : (item.netAmt + item.notaxAmt))
                        .reduce((acc, cur) => acc + cur, 0));
            obj.total =
                bsnCode.comCode === "PRE_YEAR" ?
                    (monthlyYearOverYearSales[monthlyYearOverYearSales.length - 2].total || 0) -
                    (prevTgCloseSalesStores
                        .filter(item =>
                            (loopData.code === "SALES_BY_TOTAL" ?
                                ["FOOD", "FEE"].includes(commonCodesGetCommonCodeAttrbByCodeAndIdx("STORE_CODE", item.storeCode, 3, false)) :
                                commonCodesGetCommonCodeAttrbByCodeAndIdx("STORE_CODE", item.storeCode, 3, false) === loopData.code.substring(9, loopData.code.length)))
                        .map(item => this.searchOptions.amtFlag === "true" ? item.totSalesAmt : (item.netAmt + item.notaxAmt))
                        .reduce((acc, cur) => acc + cur, 0)) :

                    bsnCode.comCode === "PRE_TOTAL" ?
                      (prevTgCloseSalesStores
                        .filter(item =>
                            (loopData.code === "SALES_BY_TOTAL" ?
                                ["FOOD", "FEE"].includes(commonCodesGetCommonCodeAttrbByCodeAndIdx("STORE_CODE", item.storeCode, 3, false)) :
                                commonCodesGetCommonCodeAttrbByCodeAndIdx("STORE_CODE", item.storeCode, 3, false) === loopData.code.substring(9, loopData.code.length)))
                        .map(item => this.searchOptions.amtFlag === "true" ? item.totSalesAmt : (item.netAmt + item.notaxAmt))
                        .reduce((acc, cur) => acc + cur, 0)) :

                      (thisTgCloseSalesStores
                        .filter(item =>
                            (bsnCode.comCode === "TOTAL" ? true : item.bsnCode === bsnCode.comCode) &&
                            (loopData.code === "SALES_BY_TOTAL" ?
                                ["FOOD", "FEE"].includes(commonCodesGetCommonCodeAttrbByCodeAndIdx("STORE_CODE", item.storeCode, 3, false)) :
                                commonCodesGetCommonCodeAttrbByCodeAndIdx("STORE_CODE", item.storeCode, 3, false) === loopData.code.substring(9, loopData.code.length)))
                        .map(item => this.searchOptions.amtFlag === "true" ? item.totSalesAmt : (item.netAmt + item.notaxAmt))
                        .reduce((acc, cur) => acc + cur, 0));

            if (bsnCode.comCode === "PRE_YEAR") {
              obj.isFirstHalfData = (monthlyYearOverYearSales[monthlyYearOverYearSales.length - 2].firstHalf || 0) > 0;
              obj.isSecondHalfData = (monthlyYearOverYearSales[monthlyYearOverYearSales.length - 2].secondHalf || 0) > 0;
              obj.isTotalData = (monthlyYearOverYearSales[monthlyYearOverYearSales.length - 2].total || 0) > 0;

              obj.firstHalfByPercent =
                  (prevTgCloseSalesStores
                      .filter(item =>
                          ["01", "02", "03", "04", "05", "06"].includes(getFormattedDate(item.bsnDate, "MM")) &&
                          (loopData.code === "SALES_BY_TOTAL" ?
                              ["FOOD", "FEE"].includes(commonCodesGetCommonCodeAttrbByCodeAndIdx("STORE_CODE", item.storeCode, 3, false)) :
                              commonCodesGetCommonCodeAttrbByCodeAndIdx("STORE_CODE", item.storeCode, 3, false) === loopData.code.substring(9, loopData.code.length)))
                      .map(item => this.searchOptions.amtFlag === "true" ? item.totSalesAmt : (item.netAmt + item.notaxAmt))
                      .reduce((acc, cur) => acc + cur, 0)) > 0 ?
                      Math.round(((monthlyYearOverYearSales[monthlyYearOverYearSales.length - 2].firstHalf || 0) /
                          (prevTgCloseSalesStores
                              .filter(item =>
                                  ["01", "02", "03", "04", "05", "06"].includes(getFormattedDate(item.bsnDate, "MM")) &&
                                  (loopData.code === "SALES_BY_TOTAL" ?
                                      ["FOOD", "FEE"].includes(commonCodesGetCommonCodeAttrbByCodeAndIdx("STORE_CODE", item.storeCode, 3, false)) :
                                      commonCodesGetCommonCodeAttrbByCodeAndIdx("STORE_CODE", item.storeCode, 3, false) === loopData.code.substring(9, loopData.code.length)))
                              .map(item => this.searchOptions.amtFlag === "true" ? item.totSalesAmt : (item.netAmt + item.notaxAmt))
                              .reduce((acc, cur) => acc + cur, 0))) * 100) - 100 :
                      100;
              obj.secondHalfByPercent =
                  (prevTgCloseSalesStores
                      .filter(item =>
                          ["07", "08", "09", "10", "11", "12"].includes(getFormattedDate(item.bsnDate, "MM")) &&
                          (loopData.code === "SALES_BY_TOTAL" ?
                              ["FOOD", "FEE"].includes(commonCodesGetCommonCodeAttrbByCodeAndIdx("STORE_CODE", item.storeCode, 3, false)) :
                              commonCodesGetCommonCodeAttrbByCodeAndIdx("STORE_CODE", item.storeCode, 3, false) === loopData.code.substring(9, loopData.code.length)))
                      .map(item => this.searchOptions.amtFlag === "true" ? item.totSalesAmt : (item.netAmt + item.notaxAmt))
                      .reduce((acc, cur) => acc + cur, 0)) > 0 ?
                      Math.round(((monthlyYearOverYearSales[monthlyYearOverYearSales.length - 2].secondHalf || 0) /
                          (prevTgCloseSalesStores
                              .filter(item =>
                                  ["07", "08", "09", "10", "11", "12"].includes(getFormattedDate(item.bsnDate, "MM")) &&
                                  (loopData.code === "SALES_BY_TOTAL" ?
                                      ["FOOD", "FEE"].includes(commonCodesGetCommonCodeAttrbByCodeAndIdx("STORE_CODE", item.storeCode, 3, false)) :
                                      commonCodesGetCommonCodeAttrbByCodeAndIdx("STORE_CODE", item.storeCode, 3, false) === loopData.code.substring(9, loopData.code.length)))
                              .map(item => this.searchOptions.amtFlag === "true" ? item.totSalesAmt : (item.netAmt + item.notaxAmt))
                              .reduce((acc, cur) => acc + cur, 0))) * 100) :
                      100;
              obj.totalByPercent =
                  (prevTgCloseSalesStores
                      .filter(item =>
                          (loopData.code === "SALES_BY_TOTAL" ?
                              ["FOOD", "FEE"].includes(commonCodesGetCommonCodeAttrbByCodeAndIdx("STORE_CODE", item.storeCode, 3, false)) :
                              commonCodesGetCommonCodeAttrbByCodeAndIdx("STORE_CODE", item.storeCode, 3, false) === loopData.code.substring(9, loopData.code.length)))
                      .map(item => this.searchOptions.amtFlag === "true" ? item.totSalesAmt : (item.netAmt + item.notaxAmt))
                      .reduce((acc, cur) => acc + cur, 0)) > 0 ?
                      Math.round(((monthlyYearOverYearSales[monthlyYearOverYearSales.length - 2].total || 0) /
                          (prevTgCloseSalesStores
                              .filter(item =>
                                  (loopData.code === "SALES_BY_TOTAL" ?
                                      ["FOOD", "FEE"].includes(commonCodesGetCommonCodeAttrbByCodeAndIdx("STORE_CODE", item.storeCode, 3, false)) :
                                      commonCodesGetCommonCodeAttrbByCodeAndIdx("STORE_CODE", item.storeCode, 3, false) === loopData.code.substring(9, loopData.code.length)))
                              .map(item => this.searchOptions.amtFlag === "true" ? item.totSalesAmt : (item.netAmt + item.notaxAmt))
                              .reduce((acc, cur) => acc + cur, 0))) * 100) - 100 :
                      100;
            }

            monthlyYearOverYearSales.push(JSON.parse(JSON.stringify(obj)));
          });
        }
      });

      // this.monthlyYearOverYearSales = monthlyYearOverYearSales;
      this.monthlyYearOverYearSales = monthlyYearOverYearSales.filter((_, index) => index < 5 || index > 9);
      console.log("this.monthlyYearOverYearSales:", this.monthlyYearOverYearSales);
      this.reportSearchOptions = JSON.parse(JSON.stringify(this.searchOptions));
    },
    onClickExcel() {
      this.$refs.bodyGrid.excelExport();
    },
    print() {
      const jsonData = this.$refs.bodyGrid.getBatchCurrentViewRecords();

      if (jsonData.length < 1) {
        return this.errorToast(this.$t("report.popupMessage.noData"));
      }

      const reportData = {
        reportJson: {
          jsonData: {
            monthlyYearOverYearSales: jsonData,
          },
          username: this.username,
          uniqueCode: this.$options.name,
          searchOptions:
            "▣ 영업년도 : " + this.reportSearchOptions.year + "년",
        },
      };
      this.$refs.reportViewComponent.postReport(reportData, this.$options.name);
    },
    onBodyGridHeaderCellInfo(args) {
      const {
        cell: {
          column: {
            field
          }
        }
      } = args;

      if (field === "title") {
        args.node.colSpan = 2;
      } else if (["name","div"].includes(field)) {
        args.cell.isSpanned = true;
      }
    },
    onBodyGridQueryCellInfo(args) {
      const {
        cell,
        column: {
          field,
          headerText,
        },
        data,
      } = args;

      if (data[field] === 0) {
        cell.innerText = "-";
      }

      if (field === "title") {
        if (data.isTitleRowSpan) {
          args.rowSpan = data.titleRowSpan;
        }
      } else if (field === "name") {
        if (data.isNameRowSpan) {
          args.rowSpan = data.nameRowSpan;
          cell.style.borderBottom = "1px solid";
        }
        if (data.isNameColSpan) {
          args.colSpan = data.nameColSpan;
        }
      }

      if ((data.isBottomSolidLine || field === "title") && !(field === undefined && headerText === "NO")) {
        cell.style.borderBottom = "1px solid";
      }

      if (data.isTotal && !(field === undefined && headerText === "NO")) {
        cell.style.backgroundColor = "#ececec";
      }
      if (data.isPrevTotal && !(field === undefined && headerText === "NO")) {
        cell.style.backgroundColor = "#ececec";
      }
      if (data.isPrevYear && !(field === undefined && headerText === "NO")) {
        cell.style.backgroundColor = "#fef2cb";

        if (data[field] < 0) {
          cell.style.color = "#ff0000";
          cell.innerText = "▼ ".concat(numberWithCommas(Math.abs(data[field]).toString()));
          data[field] = "▼ ".concat(numberWithCommas(Math.abs(data[field]).toString()));
        } else if (data[field] > 0) {
          cell.style.color = "#0000ff";
          cell.innerText = "▲ ".concat(numberWithCommas(Math.abs(data[field]).toString()));
          data[field] = "▲ ".concat(numberWithCommas(Math.abs(data[field]).toString()));
        }

        if (["01","02","03","04","05","06","07","08","09","10","11","12"].includes(field.substring(field.length - 2, field.length))) {
          if (data["isData".concat(field.substring(field.length - 2, field.length))]) {
            cell.innerText += " (".concat(Math.abs(data["dataByPercent".concat(field.substring(field.length - 2, field.length))])).concat("%)");
            data[field] += " (".concat(Math.abs(data["dataByPercent".concat(field.substring(field.length - 2, field.length))])).concat("%)");
            cell.innerHTML = "<div style='height: 24px;'>".concat(cell.innerHTML.substring(0, cell.innerHTML.indexOf("("))).concat("</div>").concat(cell.innerHTML.substring(cell.innerHTML.indexOf("("), cell.innerHTML.length));
            cell.height = 48;
          }
        } else if (field === "firstHalf") {
          if (data["isFirstHalfData"]) {
            cell.innerText += " (".concat(Math.abs(data[field.concat("ByPercent")])).concat("%)");
            data[field] += " (".concat(Math.abs(data[field.concat("ByPercent")])).concat("%)");
            cell.innerHTML = "<div style='height: 24px;'>".concat(cell.innerHTML.substring(0, cell.innerHTML.indexOf("("))).concat("</div>").concat(cell.innerHTML.substring(cell.innerHTML.indexOf("("), cell.innerHTML.length));
            cell.height = 48;
          }
        } else if (field === "secondHalf") {
          if (data["isSecondHalfData"]) {
            cell.innerText += " (".concat(Math.abs(data[field.concat("ByPercent")])).concat("%)");
            data[field] += " (".concat(Math.abs(data[field.concat("ByPercent")])).concat("%)");
            cell.innerHTML = "<div style='height: 24px;'>".concat(cell.innerHTML.substring(0, cell.innerHTML.indexOf("("))).concat("</div>").concat(cell.innerHTML.substring(cell.innerHTML.indexOf("("), cell.innerHTML.length));
            cell.height = 48;
          }
        } else if (field === "total") {
          if (data["isTotalData"]) {
            cell.innerText += " (".concat(Math.abs(data[field.concat("ByPercent")])).concat("%)");
            data[field] += " (".concat(Math.abs(data[field.concat("ByPercent")])).concat("%)");
            cell.innerHTML = "<div style='height: 24px;'>".concat(cell.innerHTML.substring(0, cell.innerHTML.indexOf("("))).concat("</div>").concat(cell.innerHTML.substring(cell.innerHTML.indexOf("("), cell.innerHTML.length));
            cell.height = 48;
          }
        }
      }

      if (
        field &&
        (field.startsWith("firstHalf") ||
          field.startsWith("secondHalf") ||
          field.endsWith("total") ||
          field.endsWith("Total"))
      ) {
        cell.style.backgroundColor = "#fef2cb";
      }
    },
  }
};
</script>
